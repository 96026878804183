async function CreateWindowMission() {
  let w = CreateWindow(`Mission`, 'fal fa-user-secret');
  let parent = w.body;

  w.window.data.ip = appData.ip;
  parent.classList.add("panelMission");

  w.Refresh = async function() {
    await BuildTable();
  }

  async function BuildTable() {
    parent.innerHTML = "";
    let missionWrapper = CreateElement("div", parent, "missionListWrapper");
    let ulActiveMissions = CreateElement("ul", missionWrapper, "activeMissionList");
    let ulMissions = CreateElement("ul", missionWrapper, "missionList");
    let data = (await API(`missions`, "GET", null));
    let missions = data.missions;
    let activeMissions = data.activeMissions;
    let description, reward, btnAccept, btnFinish, details;

    li = CreateElement("li", ulActiveMissions, 'li-heading');
    li.innerText = `Active Missions`;

    for (let mission of activeMissions) {
      let li = CreateElement("li", ulActiveMissions);
      li.innerText = `${mission.title}`;
      li.mission = mission;
      li.onclick = function() {
        details.data = li.mission;
        reward.innerText = `Reward: $${li.mission.reward}`;
        description.innerText = li.mission.instructions;
        btnAccept.style.display = 'none';
        btnFinish.style.display = 'block';
      }
    }

    li = CreateElement("li", ulActiveMissions, 'li-heading');
    li.innerText = `New Missions`;

    for (let mission of missions) {
      let li = CreateElement("li", ulMissions);
      li.innerText = `${mission.title}`;
      li.mission = mission;
      li.onclick = function() {
        details.data = li.mission;
        reward.innerText = `Reward: $${li.mission.reward}`;
        description.innerText = li.mission.description;
        btnAccept.style.display = 'block';
        btnFinish.style.display = 'none';
      }
    }

    details = CreateElement("div", parent, "missionDetails");
    reward = CreateElement("p", details, "missionReward");
    line = CreateElement("p", details, "missionBreak");
    line.innerText = "-----------------------------";
    description = CreateElement("p", details, "missionDescription");
    btnAccept = CreateElement("a", details, "missionAccept");
    btnAccept.innerText = "Accept";
    btnAccept.onclick = async function() {
      await API(`missions`, `POST`, JSON.stringify(details.data));
      await BuildTable();
      events.emit('refreshEmails');
    }

    btnFinish = CreateElement("a", details, "missionFinish");
    btnFinish.innerText = "Finish";
    btnFinish.onclick = async function() {
      await API(`completemission`, `POST`, JSON.stringify(details.data));
      await BuildTable();
      events.emit('refreshMoney');
    }
  }

  await BuildTable();
}