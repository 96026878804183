async function CreateWindowSlaves() {
  let w = CreateWindow(`Slaves`, 'fad fa-address-book');
  let parent = w.body;
  let data;

  w.window.data.ip = appData.ip;
  w.SetTitle(`Slaves @ ${w.window.data.ip}`);

  w.Refresh = async function() {
    await BuildTable();
  }

  parent.classList.add("panelSlaves");

  let table = CreateElement("table", parent);

  async function BuildTable() {
    data = await API(`slaves?ip=${w.window.data.ip}`, "GET", null);
    let slaves = data.slaves[0];
    table.innerHTML = "";
    let row = CreateElement("tr", table);
    let th = CreateElement("td", row); //should be th
    th.innerHTML = `IP`
    th = CreateElement("td", row); //should be th
    th.innerHTML = `Pass`

    if (data.slaves != null) {
      for (let slave of data.slaves) {
        let row = CreateElement("tr", table);

        let td = CreateElement("td", row);
        td.innerHTML = `<i class="fad fa-virus" style="margin-right:5px"></i>${slave.ip}`

        td = CreateElement("td", row);
        td.innerHTML = `${slave.pass}`
      }
    }
  }

  await BuildTable();
}