const events = new Events();

const _appData = {};

const ProxyHandler = {
  get: function(obj, prop, receiver) {
    return obj[prop];
  },
  set: function(obj, prop, value) {
    if(typeof value == "object" && value != null)
      throw new Error("Appdata can't contain objects")
    events.emit(`appdata-update-${prop}`, value);
    obj[prop] = value;
    return true;
  }
};

const appData = new Proxy(_appData, ProxyHandler);

let programs = {
  '<i class="far fa-book-alt"></i>\nLogs': CreateWindowLog,
  '<i class="fas fa-laptop-code"></i>\nTerminal': CreateWindowTerminal,
  '<i class="far fa-browser"></i>\nInternet': CreateWindowInternet,
  '<i class="fas fa-folders"></i>\nFiles': CreateWindowFiles,
  '<i class="fad fa-address-book"></i>\nSlaves': CreateWindowSlaves,
  '<i class="fal fa-money-check-alt"></i>\nMoney': CreateWindowMoney,
  '<i class="fal fa-server"></i>\nDatabase': CreateWindowDatabase,
  '<i class="fal fa-user-secret"></i>\nMissions': CreateWindowMission,
  '<i class="fal fa-envelope"></i></i>\nEmails': CreateWindowEmail,
  '<i class="fab fa-spotify"></i></i>\nSpotify': CreateWindowSpotify,
  '<i class="fal fa-desktop-alt"></i></i>\nMy PC': CreateWindowComputer
};

async function API(endpoint, method, body) {
  let data = await fetch(`${window.location.origin}/api/${endpoint}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  });
  let json = await data.json();
  return json;
}

let consoleCommands = {
  "ping": async (args, _) => {
    if (args.length == 0) return "No server specified"
    let ret = await API(`ping/${args}`, 'GET');
    return ret.count == 1 ? "Pong" : "Unable to connect";
  },
  "connect": async (args, w) => {
    if (args.length == 0) return "No server specified"

    let ret = await API("connect", "POST", JSON.stringify({
      from: appData.ip,
      to: args[0]
    }));

    if (ret.connected) {
      w.window.data.ip = args[0];
      w.SetTitle(`Terminal @ ${args[0]}`);
    }

    return ret.ret;
  },
  "disconnect": async (args, w) => {
    let ret = await API("disconnect", "POST", JSON.stringify({
      from: appData.ip,
      to: args.length == 0 ? w.window.data.ip : args[0]
    }));

    if (ret.disconnected) {
      events.emit('disconnect', w.window.data.ip);
      w.window.data.ip = appData.ip;
      w.SetTitle(`Terminal @ ${w.window.data.ip}`);

      return 'Disconnected';
    }

    return 'Failed to disconnect';
  },
  "pass": async (args, w) => {
    let ret = await API("passtest", "POST", JSON.stringify({
      conn: w.window.data.ip,
      myip: appData.ip
    }));

    return JSON.stringify(ret);
  },
  "setlogs": async (args, w) => {
    await UpdateLogs(w.window.data.ip == null ? null : w.window.data.ip, args.join(' '));
    return "Logs Set"; //check if worked
  },
  "logs": async (args, w) => {
    if (args[0] != null) {
      CreateWindowLog(args[0]);
      return `Opened logs from ${args}`
    } else if (args.length <= 0) {
      CreateWindowLog(w.window.data.ip);
      return `Opened logs`;
    }
  },
  "files": async (args, w) => {
    if (args[0] != null) {
      CreateWindowFiles(args[0]);
      return `Opened files from ${args}`
    } else if (args.length <= 0) {
      CreateWindowFiles(w.window.data.ip);
      return `Opened files`;
    }
  },
  "ls": async (args, w) => {
    let ip = args[0] != null ? args[0] : w.window.data.ip;
    let data = await API(`files?ip=${ip}`, "GET", null);
    let str = `<table>`;
    str += data.files.map(x => `<tr><td>${x.name}</td></tr>`).join(' ');
    str += `</table>`;
    return str;
  },
  "help": async (args, w) => {
    return consoleCommandsArr.join(', ');
  },
  "db": async (args, w) => {
    if (args[0] != null) {
      CreateWindowDatabase(args[0]);
      return `Opened db from ${args}`
    } else if (args.length <= 0) {
      CreateWindowDatabase(w.window.data.ip);
      return `Opened db`;
    }
  },
  "scanports": async (args, _) => {
    if (args.length == 0) return "No server specified"
    let ret = await API(`scanports?ip=${args}`, 'GET');
    let ports = ret.ports.map(x => `${x.number} - ${x.name}`);
    return ports;
  },
  "exploit_ssh": async (args, _) => {
    if (args.length == 0) return "No server specified"
    let ret = await API(`exploit`, 'POST', JSON.stringify({
      type: "ssh",
      ip: args[0]
    }));
    return ret.msg
  },
  "exploit_smtp": async (args, _) => {
    if (args.length == 0) return "No server specified"
    let ret = await API(`exploit`, 'POST', JSON.stringify({
      type: "smtp",
      ip: args[0]
    }));
    return ret.msg
  },
  "exploit_telnet": async (args, _) => {
    if (args.length == 0) return "No server specified"
    let ret = await API(`exploit`, 'POST', JSON.stringify({
      type: "telnet",
      ip: args[0]
    }));
    return ret.msg
  },
  "exploit_ftp": async (args, _) => {
    if (args.length == 0) return "No server specified"
    let ret = await API(`exploit`, 'POST', JSON.stringify({
      type: "ftp",
      ip: args[0]
    }));
    return ret.msg
  },
  "alias": async (args, _) => {
    if (args.length < 2) return "Not enough args"
    let alias = args.shift();
    consoleAliases[alias] = args.join(" ");
    return 'Alias Added'
  }
}

function Bind(elem, val, prop = 'innerText') {
  if(typeof elem === "string")
    elem = document.querySelector(elem);
  events.on(`appdata-update-${val}`, v => {
    elem[prop] = v;
  });
}

Bind('#ip', 'ip');
Bind('#pass', 'pass');

events.on('appdata-update-background', v => {
  SetBackground(v);
});


let consoleCommandsArr = Object.keys(consoleCommands);
let consoleAliases = {};

const eAppList = document.querySelectorAll(".bottombar ul")[0];
const eDesktop = document.querySelector(".desktop");
let currentZ = 1;

document.addEventListener('contextmenu', event => event.preventDefault());

(async () => {
  let ret = await API(`getinfo`, `GET`);
  
  for(let key of Object.keys(ret)) {
    appData[key] = ret[key];
  }
  setInterval(UpdateClock, 100);
})();

function UpdateClock() {
  let time = new Date();
  let h = time.getHours();
  let m = time.getMinutes();
  let s = time.getSeconds();
  if (h < 10) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }
  document.getElementById("clock").innerHTML = h + ':' + m + ':' + s;
}

for (let program in programs) {
  CreateDesktopIcon(program, programs[program]);
}

SortDesktopIcons();

function CreateDesktopIcon(title, ondlclick) {
  let icon = CreateElement("div", eDesktop, "desktop-icon");
  icon.innerHTML = title;
  icon.ondblclick = ondlclick;
  SetupDesktopIcon(icon);
}

function EscapeHTML(html) {
  return html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

function CreateElement(type, parent, ...classes) {
  let el = document.createElement(type);
  el.classList = classes;

  if (parent != null)
    parent.appendChild(el);

  return el;
}

//this needs a redesign
function CreateWindow(sTitle, iconClasses) {
  var w = CreateElement("div", document.querySelector('.desktop'), "window");

  var bar = CreateElement("div", w, "bar");
  CreateElement("i", bar, "icon " + iconClasses);
  var title = CreateElement("p", bar);
  title.innerText = sTitle;
  var pullRight = CreateElement("div", bar, "pull-right");

  var btnRefresh = CreateElement("i", pullRight, "bar-button far fa-sync-alt");
  btnRefresh.onclick = function() {
    ret.Refresh();
  }

  var btnMinimize = CreateElement("i", pullRight, "bar-button far fa-window-minimize");
  btnMinimize.onclick = function() {
    w.style.display = 'none';
  }

  var btnClose = CreateElement("i", pullRight, "bar-button far fa-window-close");
  btnClose.onclick = function() {
    w.parentNode.removeChild(w);
    li.parentNode.removeChild(li);
  }


  CreateElement("div", w, "resizer");
  let body = CreateElement("div", w, "body");
  w.setAttribute('tabindex', '0');
  SetupWindow(w);

  let badconnBack = CreateElement("div", body, "badconnback");
  let badconn = CreateElement("div", badconnBack, "badconn");
  let h1 = CreateElement("h1", badconn);
  h1.innerText = "Connection interrupted";

  var li = CreateElement("li", eAppList);
  li.onclick = function() {
    w.style.display = 'block';
    SelectWindow(w);
  }
  var btn = CreateElement("a", li);
  btn.innerText = sTitle;

  w.data = {};

  events.on('disconnect', ip => {
    if (ip == w.data.ip)
      badconnBack.style.display = 'block';
  });

  let ret = {
    Refresh() {
      console.log("Default window refresh");
    },
    window: w,
    body: body,
    SetTitle(t) {
      btn.innerText = t;
      title.innerText = t;
    }
  }

  return ret;
}

function SelectWindow(window) {
  window.focus();
  window.style.zIndex = ++currentZ;
}

function SetupWindow(el) {
  let isResizing = false;
  el.style.zIndex = ++currentZ;

  el.addEventListener("mousedown", () => {
    el.style.zIndex = ++currentZ;
    el.focus();
  });

  const bar = el.querySelector(".bar");
  bar.addEventListener("mousedown", MouseDown);

  function MouseDown(e) {
    window.addEventListener('mousemove', MouseMove);
    window.addEventListener('mouseup', MouseUp);

    let prevX = e.clientX;
    let prevY = e.clientY;

    function MouseMove(e) {
      if (isResizing) return;

      let newX = prevX - e.clientX;
      let newY = prevY - e.clientY;

      const rect = el.getBoundingClientRect();

      el.style.left = rect.left - newX + "px";
      el.style.top = rect.top - newY + "px";

      prevX = e.clientX;
      prevY = e.clientY;
    }

    function MouseUp(e) {
      window.removeEventListener('mousemove', MouseMove);
      window.removeEventListener('mouseup', MouseUp);
    }
  }
}

function SetBackground(color) {
  document.querySelector('.desktop').style.backgroundColor = color;
}

function SetupDesktopIcon(el) {

  el.addEventListener("mousedown", MouseDown);

  function MouseDown(e) {
    window.addEventListener('mousemove', MouseMove);
    window.addEventListener('mouseup', MouseUp);

    let prevX = e.clientX;
    let prevY = e.clientY;

    function MouseMove(e) {
      let newX = prevX - e.clientX;
      let newY = prevY - e.clientY;

      const rect = el.getBoundingClientRect();

      el.style.left = rect.left - newX + "px";
      el.style.top = rect.top - newY + "px";

      prevX = e.clientX;
      prevY = e.clientY;
    }

    function MouseUp(e) {
      window.removeEventListener('mousemove', MouseMove);
      window.removeEventListener('mouseup', MouseUp);
    }
  }
}

function SortDesktopIcons() {
  let icons = document.querySelectorAll(".desktop-icon");

  let offset = 0;

  for (let icon of icons) {
    icon.style.top = "32px";
    icon.style.left = 8 + offset + "px";
    offset += 72;
  }
}
