function LoadInternetFromIP(ip, wrapper) {
  //redo to use API
  fetch(`${window.location.origin}/api/site/${ip}`)
    .then(function(response) {
      if (response.status !== 200) {
        console.log("Fetch Failed");
        return;
      }
      response.json().then(function(data) {
        if (data.site != null)
          wrapper.closest('.wrapper-render').innerHTML = data.site;
        else
          wrapper.closest('.wrapper-render').innerHTML = `No server running on this IP`;
        //links
        wrapper.addEventListener('click', function(event) {
          if (!event.target.matches('.link')) return;
          event.preventDefault();
          LoadInternetFromIP(event.target.getAttribute('ip'), wrapper);
        }, false);

        wrapper.parentElement.querySelector('.wrapper-login').style.display = 'block';
        wrapper.parentElement.querySelector('.wrapper-login a').setAttribute('onclick', `LoadConnectFromIP("${data.ip}", this.parentElement.parentElement.querySelector('.wrapper-render'))`);

      });
    });
}

function LoadConnectFromIP(ip, wrapper) {
  wrapper.innerHTML = "";
  let h1 = CreateElement("h1", wrapper, "");
  h1.innerHTML = ip;
  let btn = CreateElement("button", wrapper, "");
  btn.innerHTML = "Login";

  wrapper.parentElement.querySelector('.wrapper-login').style.display = 'none';
}

function CreateWindowInternet() {
  let w = CreateWindow('Internet', 'fal fa-browser');
  let parent = w.body;

  parent.classList.add("panelInternet");

  let wrapperInternetControls = CreateElement("div", parent, 'wrapper-internet-controls');
  let input = CreateElement("input", wrapperInternetControls, "");

  let wrapperLogin = CreateElement("div", parent, 'wrapper-login');
  wrapperLogin.innerHTML = `You got a ping from the server. <a href='#' onclick='LoadConnectFromIP("", this)'>Login</a>`;

  let wrapperRender = CreateElement("div", parent, "wrapper-render");
  LoadInternetFromIP("1.1.1.1", wrapperRender);

  input.onkeyup = function(e) {
    if (e.key == "Enter") {
      LoadInternetFromIP(input.value, wrapperRender);
    }
  }
}