let screenSaverActive = false;
var screenSaverTime;

function DetectInactivity() {
  // DOM Events
  document.onmousemove = ResetTimer;
  document.onkeypress = ResetTimer;
  ResetTimer();

}

function ResetTimer() {
  clearTimeout(screenSaverTime);
  if (!screenSaverActive) {
    screenSaverTime = setTimeout(ScreenSaver, 60000);
  }
}

//BUG: Stacks
function ScreenSaver() {
  screenSaverActive = true;
  let area = CreateElement("div", document.body, "area");
  let ul = CreateElement("ul", area, "circles");
  for (let i of [...Array(10).keys()]) {
    CreateElement("li", ul);
  }

  area.onclick = function() {
    area.remove();
    screenSaverActive = false;
    ResetTimer();
  }
}

DetectInactivity();