async function LoadLogs(ip, textarea) {
  let data = await API(`logs?ip=${ip}`, "GET", null);
  textarea.value = data.error != null ? data.error : data.logs;
  textarea.disabled = data.error != null;
}

async function UpdateLogs(ip, text) {
  return await API("clearlogs", "POST", JSON.stringify({
    ip: ip,
    logs: text
  }));
}

function CreateWindowLog(ip) {
  let w = CreateWindow('Logs', 'fad fa-list');
  let parent = w.body;

  if (typeof ip === "string") {
    w.window.data.ip = ip;
  } else {
    w.window.data.ip = appData.ip;
  }

  parent.classList.add("panelLog");

  let heading = CreateElement("h1", parent);
  heading.innerText = `${w.window.data.ip}`;

  let textarea = CreateElement("textarea", parent);

  w.Refresh = async function() {
    await LoadLogs(w.window.data.ip, textarea);
  }

  let btnR = CreateElement("button", parent);
  btnR.innerText = "Refresh";
  btnR.onclick = async function() {
    await LoadLogs(w.window.data.ip, textarea);
  }

  let btn = CreateElement("button", parent);
  btn.innerText = "Submit";
  btn.onclick = async () => {
    let ret = await UpdateLogs(w.window.data.ip, textarea.value);
    if (ret.error != null) {
      textarea.value = ret.error;
      textarea.disabled = true;
    } else {
      textarea.value = ret.logs;
    }
  };

  LoadLogs(w.window.data.ip, textarea);
}