function CreateWindowTerminal() {
  let w = CreateWindow(`Terminal @ ${appData.ip}`, 'fad fa-list');
  let parent = w.body;

  w.window.data = {
    ip: appData.ip
  }

  w.Refresh = async function() {
    //Clear and reset
    w.window.data = {
      ip: appData.ip
    }

    w.SetTitle(`Terminal @ ${appData.ip}`);
    wrapperOutput.innerHTML = "";
    input.value = "";
  }

  parent.classList.add("panelTerminal");

  let wrapperOutput = CreateElement("div", parent);

  let wrapperInput = CreateElement("div", parent, 'input-wrapper');
  let span = CreateElement("span", wrapperInput);
  span.innerHTML = "$&nbsp;"
  let input = CreateElement("input", wrapperInput, 'input');
  let inputSuggest = CreateElement("input", wrapperInput, 'input-suggest');

  input.onkeydown = async function(e) {
    if (e.keyCode == 9) {
      e.preventDefault();

      input.value = inputSuggest.value;
    }
  }

  input.onkeyup = async function(e) {
    if (input.value.length > 0) {
      let x = consoleCommandsArr.find(x => x.startsWith(input.value));
      inputSuggest.value = x != undefined ? x : "";

    } else {
      inputSuggest.value = "";
    }

    if (e.key == "Enter") {
      let args = input.value.split(' ');
      let cmd = args.shift();

      //need a better way later
      let test = await API(`terminal?ip=${w.window.data.ip}`, 'GET', null);
      if (test.conn == false) {
        events.emit('disconnect', w.window.data.ip);
        return;
      }

      let command = consoleCommands[cmd];

      if(command == null) {
        let alias = consoleAliases[cmd];
        args = alias.split(' ');
        cmd = args.shift();
        command = consoleCommands[cmd];
      }
      
      let entry = CreateElement("div", wrapperOutput, "terminalEntry");

      if (command != null)
        if (typeof command == "string")
          entry.innerHTML = `$ ${command}`;
        else
          entry.innerHTML = `<p>$ ${input.value}</p>${await command(args, w)}`;
      else
        entry.innerHTML = `$ Invalid Command`;
      input.value = "";
    }
  }
}