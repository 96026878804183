class Events {
  constructor() {
    this.events = {};
  }

  on(name, cb) {
    if (!this.events[name])
      this.events[name] = [];
    this.events[name].push(cb);
  }

  emit(name, data) {
    if (!this.events[name]) {
      console.log(`Tried to Emit Event: `, name, data);
      return;
    }
      console.log(`Emitting Event: `, name, data);
      
    const fireCallbacks = cb => {
      cb(data);
    }

    this.events[name].forEach(fireCallbacks);
  }

  removeListener(name, listenerToRemove) {
    if (!this.events[name])
      throw new Error(`Can't remove a listener. Event "${name}" doesn't exist`);

    const filterListeners = l => l !== listenerToRemove;
    this.events[name] = this.events[name].filter(filterListeners);
  }
}