async function CreateWindowImageViewer(imgSrc) {
  let w = CreateWindow(`Image`, 'fal fa-image');
  let parent = w.body;

  w.window.data.ip = appData.ip;
  parent.classList.add("panelImageViewer");

  w.Refresh = async function() {
    await BuildTable();
  }

  events.on('refreshEmails', () => {
    w.Refresh();
  });

  async function BuildTable() {
    parent.innerHTML = "";
    let image = CreateElement("img", parent, 'imageViewerImage');
    image.src = `/fileImages/${imgSrc}`;

    //Start of PDF viewer
    // let image = CreateElement("embed", parent, 'imageViewerImage');
    // image.src = `/Beleth.pdf`;
  }

  await BuildTable();
}