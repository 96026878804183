async function CreateWindowMoney() {
  let w = CreateWindow(`Money`, 'fal fa-money-check-alt');
  let parent = w.body;

  w.window.data.ip = appData.ip;
  w.SetTitle(`Money @ ${w.window.data.ip}`);

  parent.classList.add("panelMoney");

  let table = CreateElement("table", parent);

  w.Refresh = async function() {
    await BuildTable();
  }

  events.on('refreshMoney', () => {
    w.Refresh();
  });

  events.on('money', async data => {
    await BuildTable(data);
  });

  async function BuildTable(data) {

    if (data == null) {
      data = await API(`banks`, "GET", null);
      events.emit('money', data);
    }
    table.innerHTML = "";
    let row = CreateElement("tr", table);
    let th = CreateElement("td", row); //should be th
    th.innerHTML = `User`
    th = CreateElement("td", row); //should be th
    th.innerHTML = `Pass`
    th = CreateElement("td", row); //should be th
    th.innerHTML = `Money`

    if (data.banks != null) {
      for (let bank of data.banks) {
        let row = CreateElement("tr", table);

        let td = CreateElement("td", row);
        td.innerHTML = `${bank.username}`

        td = CreateElement("td", row);
        td.innerHTML = `${bank.pass}`

        td = CreateElement("td", row);
        td.innerHTML = `$${bank.money}`
      }
    }
  }

  await BuildTable();
}