async function CreateWindowDatabase(ip) {
  let w = CreateWindow(`Database`, 'fal fa-server');
  let parent = w.body;

  if (typeof ip === "string") {
    w.window.data.ip = ip;
    w.window.data.mine = ip == appData.ip;
  } else {
    w.window.data.ip = appData.ip;
    w.window.data.mine = true;
  }

  w.SetTitle(`Database @ ${w.window.data.ip}`);

  parent.classList.add("panelDatabase");

  let table = CreateElement("table", parent);

  w.Refresh = async function() {
    await BuildTable();
  }

  async function BuildTable() {

    let data = await API(`dbschool?ip=${w.window.data.ip}`, "GET", null);

    if (data.error != null) {
      events.emit('disconnect', w.window.data.ip);
      return;
    }

    table.innerHTML = "";

    let row = CreateElement("tr", table);
    let th = CreateElement("th", row);
    th.innerText = "Name";
    th = CreateElement("th", row);
    th.innerText = "Grade";

    for (let r of data.data) {
      row = CreateElement("tr", table);

      let td = CreateElement("td", row);
      td.innerHTML = r.name;
      td.setAttribute('contenteditable', "true");
      td.setAttribute('data-id', r.id);
      td.setAttribute('data-col', 'name');

      td = CreateElement("td", row);
      td.innerHTML = r.grade;
      td.setAttribute('contenteditable', "true");
      td.setAttribute('data-id', r.id);
      td.setAttribute('data-col', 'grade');
    }

    var contents = table.querySelectorAll("[contenteditable=true]");
    [].forEach.call(contents, function(content) {
      // When you click on item, record into `data-initial-text` content of this item.
      content.addEventListener("focus", function() {
        content.setAttribute("data-initial-text", content.innerHTML);
      });
      // When you leave an item...
      content.addEventListener("blur", async function() {
        // ...if content is different...
        if (content.getAttribute("data-initial-text") !== content.innerHTML) {
          // ... do something.
          console.log("New data when content change.");
          console.log(content.getAttribute('data-id'), content.getAttribute('data-col'), content.innerText);
          //DO POST SUBMIT 
          await API(`dbschool`, `POST`, JSON.stringify({
            id: content.getAttribute('data-id'),
            col: content.getAttribute('data-col'),
            value: content.innerText
          }));
        }
      });
    });
  }

  await BuildTable();
}