async function CreateWindowEmail() {
  let w = CreateWindow(`Emails`, 'fal fa-envelope');
  let parent = w.body;

  w.window.data.ip = appData.ip;
  parent.classList.add("panelEmail");

  let table = CreateElement("table", parent);

  w.Refresh = async function() {
    await BuildTable();
  }

  events.on('refreshEmails', () => {
    w.Refresh();
  });

  async function BuildTable() {
    parent.innerHTML = "";
    let emailsWrapper = CreateElement("div", parent, "emailsListWrapper");
    let ulEmails = CreateElement("ul", emailsWrapper, "emailList");
    let data = await API(`emails`, "GET", null);
    let emails = data.emails;
    let description, fileAttachment, details;

    li = CreateElement("li", ulEmails, 'li-heading');
    li.innerText = `Emails`;

    for (let email of emails) {
      let li = CreateElement("li", ulEmails);
      li.innerText = `${email.title}`;
      li.email = email;
      li.onclick = function() {
        details.data = li.email;
        description.innerText = li.email.content;
        fileAttachment.style.display = details.data.fileattachment != -1 ? 'block' : 'none';
        fileAttachment.innerText = `Download ${details.data.name}`;
      }
    }
    details = CreateElement("div", parent, "emailDetails");
    description = CreateElement("p", details, "emailDescription");
    fileAttachment = CreateElement("a", details, "emailFileAttachment");
    fileAttachment.onclick = async function() {
      await API(`downloademailattachment`, `POST`, JSON.stringify({
        id: details.data.id
      }));
      //await BuildTable();
    }
  }

  await BuildTable();
}