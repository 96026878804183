async function CreateWindowComputer(ip) {
  let w = CreateWindow(`My PC`, 'fal fa-desktop-alt');
  let parent = w.body;
  let data;

  //Can this be moved to window code?
  if (typeof ip === "string") {
    w.window.data.ip = ip;
    w.window.data.mine = ip == appData.ip;
  } else {
    w.window.data.ip = appData.ip;
    w.window.data.mine = true;
  }

  w.Refresh = async function() {

  }

  parent.classList.add("panelComputer");

  async function BuildTable() {
    let btnIp = CreateElement('button', parent)
    btnIp.innerText = "Reset IP";
    btnIp.onclick = async function() {
      let data = await API(`resetip`, "POST", null);
      appData.ip = data.ip;
    }
    let btnPass = CreateElement('button', parent)
    btnPass.innerText = "Reset Pass";
    btnPass.onclick = async function() {
      let data = await API(`resetpassword`, "POST", null);
      appData.pass = data.pass;
    }
  }

  await BuildTable();
}