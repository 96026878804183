//https://developer.spotify.com/documentation/web-playback-sdk/reference/#api-spotify-player-previoustrack

// player.getCurrentState().then(s => console.log(s.track_window.current_track.name))
// let state = await player.getCurrentState();
// let track_window = state.track_window;
// track_window.current_track.album.images[0].url

function CreateWindowSpotify() {
  let w = CreateWindow('Spotify', 'fab fa-spotify'); //change icon
  let parent = w.body;

  w.window.style.width = '300px';
  w.window.style.height = '325px';
  w.window.style.resize = 'none';
  w.window.querySelector('.resizer').remove(); //definitely need better way
  parent.classList.add("panelSpotify");

  let wrapperSpotify = CreateElement("div", parent, 'wrapper-spotify');

  let img = CreateElement("img", wrapperSpotify, "");
  let wrapperSpotifyButtons = CreateElement("div", wrapperSpotify, 'wrapper-spotify-buttons');

  let btnPrevious = CreateElement("button", wrapperSpotifyButtons, "btnPrevious");
  btnPrevious.innerHTML = "<i class='fas fa-step-backward'></i>"
  btnPrevious.onclick = function() {
    player.previousTrack().then(() => {});
  }

  let btnPlay = CreateElement("button", wrapperSpotifyButtons, "btnPlay");
  btnPlay.innerHTML = "<i class='fas fa-play'></i>"
  btnPlay.onclick = function() {
    if (w.window.data.isPaused == true) {
      player.resume().then(() => {
        btnPlay.innerHTML = "<i class='fas fa-pause'></i>"
      });
    } else {
      player.pause().then(() => {
        btnPlay.innerHTML = "<i class='fas fa-play'></i>"
      });
    }
  }

  let btnNext = CreateElement("button", wrapperSpotifyButtons, "btnNext");
  btnNext.innerHTML = "<i class='fas fa-step-forward'></i>"
  btnNext.onclick = function() {
    player.nextTrack().then(() => {});
  }

  let authBack = CreateElement("div", wrapperSpotify, "authBack")
  let btnAuth = CreateElement("button", authBack, "btnAuth");
  btnAuth.innerHTML = "Auth"
  btnAuth.onclick = function() {
    PopOutWindow('https://accounts.spotify.com/en/authorize?client_id=2b0bbfd28b39416cbe1c70b53a00b441&response_type=code&redirect_uri=http:%2F%2F127.0.0.1:8080%2Fapi%2Fcallback&scope=streaming%20user-read-private%20user-read-email&state=NULL')
  }

  w.Refresh = function() {

  }


  events.on('spotify_state_change', state => {
    //probs should cache and check these values
    let track_window = state.track_window;
    img.src = track_window.current_track.album.images[0].url;

    btnPlay.innerHTML = state.paused ? "<i class='fas fa-play'></i>" : "<i class='fas fa-pause'></i>"
    w.window.data.isPaused = state.paused;
  });

  events.on('spotify_error', val => {
    authBack.style.display = val ? 'flex' : 'none';
  });
  
  if(error) {
    authBack.style.display = 'flex';
  }
}

function PopOutWindow(url) {
  let newwindow=window.open(url,'name','height=400,width=500');
  if (window.focus) {newwindow.focus()}
  newwindow.test = function(token) {
    events.emit('spotify_error', false);
    newwindow.close();
  }
  
  return false;
}

// player.resume().then(() => {
//   console.log('Resumed!');
// });

//https://accounts.spotify.com/en/authorize?client_id=2b0bbfd28b39416cbe1c70b53a00b441&response_type=code&redirect_uri=http:%2F%2Fcollectiveofstrangers.com:8080%2Fapi%2Fcallback&scope=streaming%20user-read-private%20user-read-email&state=NULL
//https://accounts.spotify.com/en/authorize?client_id=2b0bbfd28b39416cbe1c70b53a00b441&response_type=code&redirect_uri=http:%2F%2F127.0.0.1:8080%2Fapi%2Fcallback&scope=streaming%20user-read-private%20user-read-email&state=NULL


let player;
let error;
window.onSpotifyWebPlaybackSDKReady = () => {
  player = new Spotify.Player({
    volume: 0.2,
    name: 'Web Playback SDK Quick Start Player',
    getOAuthToken: async cb => {
      fetch(`http://127.0.0.1:8080/api/spotifyToken`)
      .then(response => response.json())
      .then(data => {
        console.log("SpotifyError: ", data.error);
        error = data.error;
        cb(data.error ?  null : data.token);
      });
    }
  });

  // Error handling
  player.addListener('initialization_error', ({
    message
  }) => {
    events.emit('spotify_error', true);
    error = true;
    // console.error(message);
  });
  player.addListener('authentication_error', ({ //this one
    message
  }) => {
    events.emit('spotify_error', true);
    error = true;
    // console.error(message);
  });
  player.addListener('account_error', ({
    message
  }) => {
    events.emit('spotify_error', true);
    error = true;
    // console.error(message);
  });
  player.addListener('playback_error', ({
    message
  }) => {
    //events.emit('spotify_error', true);
    //error = true;
    // console.error(message);
  });

  // Playback status updates
  player.addListener('player_state_changed', state => {
    events.emit('spotify_state_change', state);
  });

  // Ready
  player.addListener('ready', ({
    device_id
  }) => {
    player.setVolume(0.01);
    player.setName('Hack Game');
    console.log('Ready with Device ID', device_id);
  });

  // Not Ready
  player.addListener('not_ready', ({
    device_id
  }) => {
    console.log('Device ID has gone offline', device_id);
  });

  // Connect to the player!
  player.connect();
};