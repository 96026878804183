async function CreateWindowFiles(ip) {
  let w = CreateWindow(`Files`, 'fad fa-hdd');
  let parent = w.body;
  let data;

  if (typeof ip === "string") {
    w.window.data.ip = ip;
    w.window.data.mine = ip == appData.ip;
  } else {
    w.window.data.ip = appData.ip;
    w.window.data.mine = true;
  }

  w.SetTitle(`Files @ ${w.window.data.ip}`);

  w.Refresh = async function() {
    await BuildTable();
  }

  parent.classList.add("panelFiles");

  let table = CreateElement("table", parent);
  let uploadbar;

  async function BuildTable() {
    data = await API(`files?ip=${w.window.data.ip}`, "GET", null);

    let icons = {
      "firewall": "fad fa-fire",
      "waterwall": "fal fa-tint",
      "virus": "fad fa-virus"
    }

    //check for hider
    const hasHider = data.files.map(x => x.softwaretype == 'hider').includes(true);

    table.innerHTML = "";
    if (data.files != null) {
      for (let file of data.files) {
        let row = CreateElement("tr", table);

        let td = CreateElement("td", row);
        td.innerHTML = `<i class="${icons[file.softwaretype]}" style="margin-right:5px"></i>${file.name}`;

        //Hide
        if (hasHider) {
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("hide");
          td.innerHTML = `<i class="far fa-eye-slash"></i>`;

          td.onclick = async function() {
            let ret = await API(`hidefile`, "POST", JSON.stringify({
              ip: w.window.data.ip,
              id: file.id
            }));

            if (ret.error != null) {
              //Show error notification
              console.log(ret.error);
            }

            await BuildTable();
          }
        }

        //Run
        if ((data.computer.activefirewallid != file.id && file.softwaretype == 'firewall') || //not active firewall
          file.softwaretype == 'virus' && file.fileid == null) { //not active virus
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("run");
          td.innerHTML = `<i class="fad fa-play-circle"></i>`;

          td.onclick = async function() {
            let ret = await API(`runfile`, "POST", JSON.stringify({
              ip: w.window.data.ip,
              id: file.id
            }));

            if (ret.error != null) {
              //Show error notification
              console.log(ret.error);
            }

            await BuildTable();
          }
        } else if (file.softwaretype == 'virus' && file.fileid != null) { //active virus
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("running");
          td.innerHTML = `<i class="fal fa-check-circle"></i>`;
        } else if (data.computer.activefirewallid == file.id && file.softwaretype == 'firewall') { //active firewall
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("running");
          td.innerHTML = `<i class="fal fa-check-circle"></i>`;
        } else if (file.softwaretype == 'image') { //image
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("run");
          td.innerHTML = `<i class="fad fa-play-circle"></i>`;
          td.onclick = async function() {
            CreateWindowImageViewer(file.link);
          }
        } else if (file.softwaretype == 'seeker') { //seeker
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("run");
          td.innerHTML = `<i class="fad fa-play-circle"></i>`;
          td.onclick = async function() {
            let ret = await API(`runfile`, "POST", JSON.stringify({
              ip: w.window.data.ip,
              id: file.id
            }));
            await BuildTable();
          }
        } else if (data.computer.activebackgroundid == file.id && file.softwaretype == 'background') { //active background
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("running");
          td.innerHTML = `<i class="fal fa-check-circle"></i>`;
        } else if (file.softwaretype == 'background') { //seeker
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("run");
          td.innerHTML = `<i class="fad fa-play-circle"></i>`;
          td.onclick = async function() {
            let ret = await API(`runfile`, "POST", JSON.stringify({
              ip: w.window.data.ip,
              id: file.id
            }));
            if (ret.background != undefined) {
              SetBackground(ret.background);
            }
            await BuildTable();
          }
        } else {
          td = CreateElement("td", row, 'rowbtn'); //none of the above

          td.innerHTML = `&nbsp`;
        }

        if (!w.window.data.mine) {

          //Download
          td = CreateElement("td", row, 'rowbtn');
          td.classList.add("download");
          td.innerHTML = `<i class="fal fa-download"></i>`;

          td.onclick = async function() {
            await API(`downloadfile`, "POST", JSON.stringify({
              ip: w.window.data.ip,
              id: file.id
            }));
            await BuildTable();
          }
        }

        td = CreateElement("td", row, 'rowbtn');
        td.classList.add("delete");
        td.innerHTML = `<i class="far fa-trash-alt"></i>`;

        td.onclick = async function() {
          let ret = await API(`deletefile`, "POST", JSON.stringify({
            ip: w.window.data.ip,
            id: file.id
          }));

          if (ret.error != null) {
            //Show error notification
            console.log(ret.error);
          }

          await BuildTable();
        }
      }
    } else {
      events.emit('disconnect', w.window.data.ip);
      uploadbar.style.display = 'none';
    }
  }

  await BuildTable();

  if (!w.window.data.mine && data.files != null) {
    uploadbar = CreateElement("div", parent, "uploadbar");
    let select = CreateElement("select", uploadbar);

    let data = await API(`files?ip=${appData.ip}`, "GET", null);
    for (let d of data.files) {
      let option = CreateElement("option", select);
      option.innerText = d.name;
      option.value = d.id;
    }

    let btn = CreateElement("a", uploadbar, "btnUpload");
    btn.innerText = "Upload";

    btn.onclick = async function() {
      await API(`uploadfile`, "POST", JSON.stringify({
        ip: w.window.data.ip,
        id: select.value
      }));

      await BuildTable();
    }
  }
}